<template>
  <div class="document-viewer" v-if="processedPdf">
    <!-- every pdf page -->
    <PdfPageViewer
      v-for="pageNumber in doc.pagesCount"
      :key="`${doc.id}--${pageNumber}`"
      :pdf="processedPdf"
      :pageNumber="pageNumber"
      :docId="doc.id"
      @pdf-page-rendered="onPdfPageRendered"
    />
  </div>
</template>

<script>
import PdfPageViewer from "@/components/DocumentViewer/PdfPageViewer.vue";
import { mapActions } from "vuex";

export default {
  name: "DocumentViewer",
  components: { PdfPageViewer },
  data: () => ({
    processedPdf: null,
    renderedPagesCount: 0,
    pagesCount: null,
  }),
  props: {
    doc: {
      type: Object,
      required: true,
    },
  },
  watch: {
    "doc.id": {
      immediate: true,
      handler() {
        this.processedPdf = null;
        this.processPdf(this.doc.objectUrl);
      },
    },
  },
  methods: {
    ...mapActions("doc", ["docPagesCountUpdater"]),
    // example https://mozilla.github.io/pdf.js/examples/#interactive-examples
    processPdf(objectUrl) {
      window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/es5/build/pdf.worker.min.js";
      const result = window.pdfjsLib.getDocument({ url: objectUrl });
      result.promise.then(this.onPdfProcessed).catch((reason) => {
        console.error(reason);
      });
    },
    onPdfProcessed(pdf) {
      this.processedPdf = pdf;
      this.docPagesCountUpdater({
        docId: this.doc.id,
        pagesCount: pdf.numPages,
      });
      this.pagesCount = pdf.numPages;
    },
    onPdfPageRendered() {
      this.renderedPagesCount++;
      if (this.renderedPagesCount === this.pagesCount) {
        this.$emit("pdf-rendered", { pagesCount: this.pagesCount });
      }
    },
  },
};
</script>

<style scoped></style>
