<template>
  <div class="pdf-page relative">
    <canvas class="w-full" ref="canvas"></canvas>
    <portal-target class="page-overlay-items absolute inset-0" :name="overlayPortalName"></portal-target>

    <!-- to handle click on page -->
    <portal-target
      v-if="!isPreview"
      class="page-overlay-click-getter absolute inset-0"
      :name="`page-overlay-${pageNumber}`"
    ></portal-target>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PdfPageViewer",
  inject: {
    isPreview: { default: false },
    shouldUpdateDocRenderedPageNumbers: { default: true },
  },
  props: {
    pdf: {
      type: Object,
      required: true,
    },
    pageNumber: {
      type: Number,
      required: true,
    },
    docId: { type: String },
  },
  mounted() {
    this.renderPage();
  },
  computed: {
    ...mapGetters("editor", ["docRenderedPageNumbersGet"]),
    overlayPortalName() {
      return `pdf-viewer-doc-${this.docId}-page-${this.pageNumber}${this.isPreview ? "-preview" : ""}`;
    },
  },
  methods: {
    ...mapActions("editor", ["docRenderedPageNumbersUpdate"]),
    ...mapActions("doc", ["pagesDimensionRatioUpdate"]),
    renderPage() {
      this.pdf.getPage(this.pageNumber).then((pdfPage) => {
        // scale === image quality
        var scale = 3;
        var viewport = pdfPage.getViewport({ scale: scale });

        // Prepare canvas using PDF pdfPage dimensions
        var canvas = this.$refs.canvas;
        var context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const pageDimensionRatio = viewport.height / viewport.width;
        this.pagesDimensionRatioUpdate({
          docId: this.docId,
          pageNumber: this.pageNumber,
          pageDimensionRatio,
        });

        // Render PDF pdfPage into canvas context
        var renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        var renderTask = pdfPage.render(renderContext);
        renderTask.promise.then(() => {
          this.onRenderFinished();
        });
      });
    },
    onRenderFinished() {
      this.isRendered = true;
      if (!this.shouldUpdateDocRenderedPageNumbers) {
        return;
      }
      this.$nextTick(() => {
        // todo: don't do this one by one. just set isRendered fully
        const uniquePageNumbers = [...new Set([...this.docRenderedPageNumbersGet, this.pageNumber])];
        this.docRenderedPageNumbersUpdate(uniquePageNumbers);
        this.$emit("pdf-page-rendered");
      });
    },
  },
};
</script>

<style scoped>
.page-overlay-click-getter:empty {
  display: none;
}
</style>
