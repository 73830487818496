<template>
  <div class="flex bg-orange bg-opacity-50 rounded-md cursor-pointer select-none">
    <SvgIcon
      v-if="draggable"
      class="invisible addible-position-icon absolute -top-4 -left-2 text-gray-400 w-4 h-4"
      icon="location"
    ></SvgIcon>
    <div class="flex items-center justify-center px-4 bg-orange rounded-l-md">
      <b>{{ addible.name }}</b>
      <SvgIcon class="absolute bottom-2 left-2" :icon="addible.iconName"></SvgIcon>
    </div>
    <a
      v-if="removable"
      class="absolute right-2 top-2"
      @mousedown.stop=""
      @click.stop="$emit('remove', addible)"
      title="Remove"
      ><SvgIcon icon="close" class="text-gray-darkest text-xl"
    /></a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddibleOverlayItem",
  // todo: activeAddibleGet
  ...mapGetters("editor", ["activeAddibleGet"]),
  props: {
    addible: {
      type: Object,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.is-dragging .addible-position-icon {
  visibility: visible;
}
</style>
