export function getRelativeDimensions(addible, { left, top, pageNumber }, pagesDimensionRatio, wrapperElement) {
  const { pageWidth, pageHeight } = getDocumentViewerWidth(pageNumber, pagesDimensionRatio, wrapperElement);

  const relativeLeft = left / pageWidth;
  const relativeTop = top / pageHeight;

  return { ...addible, x: relativeLeft, y: relativeTop };
}

export function getRealDimensions(addible, pagesDimensionRatio, wrapperElement) {
  const { pageWidth, pageHeight } = getDocumentViewerWidth(addible.page, pagesDimensionRatio, wrapperElement);
  const newAddible = {
    ...addible,
    x: addible.x * pageWidth,
    y: addible.y * pageHeight,
  };
  return newAddible;
}

export function getDocumentViewerWidth(pageNumber, pagesDimensionRatio, wrapperElement) {
  const documentViewerElement = wrapperElement;
  const pageWidth = documentViewerElement?.clientWidth;
  const pageHeight = pageWidth * pagesDimensionRatio[pageNumber - 1];
  return { pageWidth, pageHeight };
}
