import { debounce } from "lodash-es";
import { mapMutations } from "vuex";
import { bus } from "@/main.js";
export default {
  created() {
    window.addEventListener("resize", this.debouncedOnWindowResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.debouncedOnWindowResize);
  },
  methods: {
    ...mapMutations("appGlobal", ["windowResizedAtUpdate"]),
    debouncedOnWindowResize: debounce(function (e) {
      bus.$emit("window-resized");
    }, 500),
  },
};
